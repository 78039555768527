//uncomment the object for which you want to run 
//note: uncomment only 1 at a time

//for fora
// export const buildSettings = {
//     "packageId": "com.seesurround.roundtables",
//     "iosAppStoreId": "1530065930",
//     "subdomain": "fora"
// }

// for engfanzone
// export const buildSettings = {
//     "packageId": "com.seesurround.engfanzone",
//     "iosAppStoreId": "6443620861",
//     "subdomain": "engfanzone"
// }

// for lecol
export const buildSettings={
    "packageId":"com.seesurround.lecol",
    "iosAppStoreId":"1611412247",
    "subdomain":"lecol"
}
