// import axios from 'axios';
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as dbref, onValue } from "firebase/database";
import { getApp, initializeApp } from "firebase/app";
import { stubTrue } from "lodash";

// import * as admin from "firebase-admin";
import { firebaseDB as db } from "../../firebase";

export const RECEIVING_TABLES = "RECEIVING_TABLES";
export const RECEIVED_TABLES = "RECEIVED_TABLES";
export const RECEIVING_TABLE_ERROR = "RECEIVING_TABLE_ERROR";
export const CREATING_TABLE = "CREATING_TABLE";
export const CREATED_TABLE = "CREATED_TABLE";
export const DELETING_TABLE = "DELETING_TABLE";

// const fb = initializeApp(config.fbConfig);
// const db = getDatabase(fb);

// export function getAllTables() {
//     return (dispatch) => {
//         dispatch(receivingTables());
//         const functions_obj = getFunctions();
//         const callable_obj = httpsCallable(functions_obj, 'getMyForums');
//         callable_obj({
//             from: 0,
//             number: 100
//         }).then((value) => {
//             if (value && value.data && (value.data.status === 'SUCCESS'||value.data.status === 'JOINED')) {
//                 console.log(value.data.data);
//                 dispatch(receivedTables(value.data.data));
//             } else {
//                 dispatch(receivingTablesError({ err: 'Unable to get tables from server' }));
//             }
//         }
//         ).catch(err => {
//             dispatch(receivingTablesError({ err }));
//         });
//     }
// }

// uid,
// desired_channel_id,
// broadcaster,
// spawn_new_channel

export async function getAllChannelsForTable(
  room,
  desired_channel_id,
  broadcaster,
  spawn_new_channel
) {
  const functions_obj = getFunctions();
  const callable_obj = httpsCallable(functions_obj, "joinForumWeb");

  return callable_obj({
    forum_id: room,
    device_id: "Web",
    broadcaster: broadcaster,
    channel: desired_channel_id,
    spawn_new_channel: spawn_new_channel,
    // userId: getAuth().currentUser.uid,
  })
    .then((value) => {
      if (
        value &&
        value.data &&
        (value.data.status === "SUCCESS" || value.data.status === "JOINED")
      ) {
        // PUT LISTENR LOGIC HERE FOR THE REALTIME DB and to Get Channel
        const callrecord = dbref(db, value.data.path);

        /*let unsubscribe = onValue(callrecord, (snapshot) => {
                const data = snapshot.val();
                //updateStarCount(postElement, data);
                console.log("available?:"+JSON.stringify(callrecord));
                if (data.status === 'AVAILABLE'){
                    unsubscribe();
                    //db.off(callrecord);
                    return receivedTables(data.channel_id);
                }
            });*/
        return receivingTables(callrecord);
      } else {
        console.log("Unable to get tables from server");
        return receivingTablesError({
          err: "Unable to get tables from server",
        });
      }
    })
    .catch((err) => {
      return receivingTablesError({ err });
    });
}
export async function getAllChannels(room, channel) {
  //console.log("UID: "+JSON.stringify(getAuth().currentUser.uid));
  //console.log("Room: "+JSON.stringify(room));
  const functions_obj = getFunctions();
  // console.log("Room: " + JSON.stringify(room));
  const callable_obj = httpsCallable(functions_obj, "getChannelDetailsV2Web");
  // console.log("In here: ");

  return callable_obj({
    data: {
      forum_id: room,
      device_id: "Web",
      channel,
      get_listeners: true,
    },
    // userId: getAuth().currentUser.uid,
  })
    .then((value) => {
      // console.log(JSON.stringify(value));
      if (
        value &&
        value.data &&
        (value.data.status === "SUCCESS" || value.data.status === "JOINED")
      ) {
        // PUT LISTENR LOGIC HERE FOR THE REALTIME DB and to Get Channel
        const callrecord = dbref(db, value.data.path);
        // console.log("========?????", callrecord)
        /*let unsubscribe = onValue(callrecord, (snapshot) => {
                const data = snapshot.val();
                //updateStarCount(postElement, data);
                // console.log("available?:"+JSON.stringify(callrecord));
                if (data.status === 'AVAILABLE'){
                    unsubscribe();
                    //db.off(callrecord);
                    return receivedTables(data.channel_id);
                }
            });*/
        return receivingTables(callrecord);
      } else {
        // console.log('Unable to get tables from server');
        return receivingTablesError({
          err: "Unable to get tables from server",
        });
      }
    })
    .catch((err) => {
      return receivingTablesError({ err });
    });
}
/*export function joinChanel(foraid,rooms,broadcaster) {
    return (dispatch) => {
        const functions_obj = getFunctions();
        const callable_obj = httpsCallable(functions_obj, 'ProcessJoinCall');
        callable_obj({
            fora_id : foraid,
            rooms: rooms,
            userId: getAuth().currentUser.uid,
            device_id: "Web"
        }).then((value) => {
            // console.log(JSON.stringify(value));
            if (value && value.data && value.data.status === 'SUCCESS') {
                // console.log(JSON.stringify(value.data));
                dispatch(receivedTables(value.data.data));
            } else {
                // console.log('Unable to get tables from server' );
                dispatch(receivingTablesError({ err: 'Unable to get tables from server' }));
            }
        }
        ).catch(e => console.log(JSON.stringify(e)));
    }
}*/

// export async function uploadImages(images, type) {
//     const ret = [];
//     const current_user = getAuth().currentUser;
//     if (type === 'IMAGES') {
//         for (const image of images) {
//             const image_path = `temp_uploads/${current_user.uid}/${new Date().getTime()}${image.name}`;
//             const file_ref = ref(getStorage(), image_path);
//             try {
//                 const upload_resp = await uploadBytes(file_ref, image);
//                 console.log(upload_resp);
//                 ret.push(image_path);
//             } catch (e) {
//                 console.error(`Uploading error ${JSON.stringify(e)}`)
//                 return {
//                     status: 'FAILED'
//                 }
//             }
//         }
//         return {
//             status: 'SUCCESS',
//             data: ret
//         }
//     } else if (type === 'SPONSOR_LOGO' || type === "COVER_IMAGE") {
//         const image_path = `temp_uploads/${current_user.uid}/${new Date().getTime()}${images.name}`;
//         const file_ref = ref(getStorage(), image_path);
//         try {
//             const upload_resp = await uploadBytes(file_ref, images);
//             return {
//                 status: 'SUCCESS',
//                 data: upload_resp
//             }
//         } catch (e) {
//             console.error(`Uploading error ${JSON.stringify(e)}`)
//             return {
//                 status: 'FAILED'
//             }
//         }
//     }
//     return {
//         status: 'FAILED'
//     }
// }

// export function addTable(table) {
//     return (dispatch) => {
//         dispatch(creatingTable());
//         const functions_obj = getFunctions();
//         console.log(`Getting interface`);
//         const callable_obj = httpsCallable(functions_obj, 'createForumWeb');
//         callable_obj(table).then((value) => {
//             console.log('Values');
//             console.log(value);
//         }).catch(e => console.log(e))
//     }
// }

// export function deleteTable(tableid) {
//     return (dispatch) => {
//         dispatch(creatingTable());
//         const functions_obj = getFunctions();
// console.log(`Getting interface`);
//         const callable_obj = httpsCallable(functions_obj, 'deleteForaWeb');
//         callable_obj(tableid).then((value) => {
//             console.log('Values');
//             console.log(value);
//         }).catch(e => console.log(e))
//     }
// }

export function receivingTables(payload) {
  return {
    type: RECEIVING_TABLES,
    payload,
  };
}

export function receivedTables(payload) {
  return {
    type: RECEIVED_TABLES,
    payload,
  };
}

export function receivingTablesError(payload) {
  return {
    type: RECEIVING_TABLE_ERROR,
    payload,
  };
}

export function creatingTable() {
  return {
    type: CREATING_TABLE,
  };
}

export function deletingTable() {
  return {
    type: DELETING_TABLE,
  };
}
